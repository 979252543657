<template>
  <div class="flex">
    <div
      class="settings px-23 z-50"
      style="border: 1px solid #e6e8f0"
      :class="'pt-4'"
    >
      <div class="flex items-center cursor-pointer" @click="routeUrl()">
        <img
          class="mr-[19px] ml-6 min-h-[5.7px] w-[40px] mt-0.5"
          src="../apps/time-tracker/assets/amalitech-logo3.svg"
          alt="amalitech-logo"
        />

        <div class="mt-1">
          <a
            @click="routeUrl()"
            class="
              mt-1
              text-[27.65px]
              N700
              font-bold
              sm:hidden
              md:hidden
              lg:block
              xl:block
              mr-24
              mb-1
              hover:text-[#696F8C]
              cursor-pointer
            "
            v-if="store.showAll"
          >
            AmaliTech
          </a>
        </div>
      </div>
      <div class="flex flex-col h-[20vh]">
        <div class="mt-4 px-3"><hr /></div>

        <div class="p-4 pt-[12px]">
          <div class="">
            <NavigationComponent
              v-if="userPermissions.permissions?.time_tracker"
              :item="{
                label: $route.path.includes('/time-tracker/clock-it')
                  ? 'Clock-IT'
                  : startTimer === true
                  ? `Clock-IT  ${timeDisplay}`
                  : 'Clock-IT',
                route:
                  '/time-tracking/' + encryptedId + '/time-tracker/clock-it'
              }"
              @mouseover="toggleonClockIt(true)"
              @mouseout="toggleonClockIt(false)"
            >
              <ClockITSVG
                :color="$route.name === 'clock-it' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
              v-if="userPermissions.permissions?.plan_it"
              :item="{
                label: 'Plan-IT',
                route:
                  '/time-tracking/' + encryptedId + '/time-tracker/day-planner'
              }"
              @mouseover="toggleOnDayPlanner(true)"
              @mouseout="toggleOnDayPlanner(false)"
            >
              <PlanITSVG
                :color="$route.name === 'day-planner' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
          <div>
            <NavigationComponent
              v-if="userPermissions.permissions?.view_people"
              :item="{
                label: 'People',
                route: '/time-tracking/' + encryptedId + '/timetracker/people',
                keyWord: 'people'
              }"
              @mouseover="toggleOnPeople(true)"
              @mouseout="toggleOnPeople(false)"
            >
              <PeopleSVG
                :color="
                  $route.fullPath.includes('people') ? '#DD5928' : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
          <div class="-mb-4">
            <NavigationComponent
              v-if="userPermissions.permissions?.preference"
              :item="{
                label: 'Your Preference',
                route:
                  '/time-tracking/' + encryptedId + '/time-tracker/preference'
              }"
              @mouseover="toggleOnPreference(true)"
              @mouseout="toggleOnPreference(false)"
            >
              <YourPreferenceSVG
                :color="$route.name === 'preference' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
        </div>

        <div
          class="px-3"
          v-if="
            userPermissions.permissions?.view_people ||
            userPermissions.permissions?.view_projects ||
            userPermissions.permissions?.view_teamsheet ||
            userPermissions.permissions?.basic_reports
          "
        >
          <hr />
        </div>

        <div class="p-4 pt-3">
          <div class="">
            <NavigationComponent
              v-if="userPermissions.permissions?.view_projects"
              :item="{
                label: 'Projects',
                route:
                  '/time-tracking/' + encryptedId + '/timetracker/projects',
                keyWord: 'projects'
              }"
              @mouseover="toggleOnProjects(true)"
              @mouseout="toggleOnProjects(false)"
            >
              <ProjectsSVG
                :color="
                  $route.fullPath.includes('projects') ? '#DD5928' : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
              v-if="userPermissions.permissions?.view_teamsheet"
              :item="{
                label: 'Team Sheet',
                route: '/team-sheet' + '/' + encryptedId + '/dashboard',
                keyWord: '-sheet'
              }"
              @mouseover="toggleOnTeamSheet(true)"
              @mouseout="toggleOnTeamSheet(false)"
            >
              <TeamSheetSVG
                :color="
                  $route.fullPath.includes('team-sheet') ? '#DD5928' : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
              v-if="userPermissions.permissions?.basic_reports"
              :item="{ label: 'Report', route: '/basic-report' }"
              @mouseover="toggleOnReport(true)"
              @mouseout="toggleOnReport(false)"
            >
              <ReportSVG
                :color="$route.name === 'basic-report' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>

            <div
              class="px-0"
              v-if="
                userPermissions.permissions?.view_projects ||
                userPermissions.permissions?.view_teamsheet ||
                userPermissions.permissions?.basic_reports
              "
            >
              <hr />
            </div>
            <div class="N700 text-base font-semibold mt-4 pl-3"></div>
            <NavigationComponent
              v-if="
                userPermissions.permissions?.configurations ||
                userPermissions.permissions?.access_level
              "
              :item="{
                label: 'Settings',
                route: '/time-tracking/settings/configurations',
                keyWord: 'settings'
              }"
              @mouseover="toggleOnSettings(true)"
              @mouseout="toggleOnSettings(false)"
            >
              <SettingsSVG
                :color="
                  $route.fullPath.includes('settings') ? '#DD5928' : '#8F95B2'
                "
              />
            </NavigationComponent>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 -ml-5 z-50">
      <div class="" @click="store.showAll = !store.showAll">
        <div
          class="
            mt-2
            h-6
            w-6
            rounded-full
            flex
            items-center
            py-0
            px-2
            shadow-md
            BG0
            cursor-pointer
          "
          v-if="store.showAll"
        >
          <ArrowLeftIcon />
        </div>
        <div
          class="
            h-6
            w-6
            rounded-full
            flex
            items-center
            py-0
            px-2
            shadow-md
            BG0
            cursor-pointer
          "
          v-else
        >
          <ArrowRightIcon />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PlanITSVG from "@/assets/Plan-IT-SVG.vue";
import ClockITSVG from "@/assets/Clock-IT-SVG.vue";
import PeopleSVG from "@/assets/PeopleSVG.vue";
import ProjectsSVG from "@/assets/ProjectsSVG.vue";
import TeamSheetSVG from "@/assets/TeamSheetSVG.vue";
import ReportSVG from "@/assets/ReportSVG.vue";
import SettingsSVG from "@/assets/SettingsSVG.vue";
import NavigationComponent from "./NavigationComponent.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "../store";
import YourPreferenceSVG from "@/apps/time-tracker/assets/YourPreferenceSVG.vue";
import { encodeUrlPath } from "../helpers/utils";
import { clockItUserPermissions } from "@/apps/time-tracker/store/clock_it_user_permissions";
import { useClockStore } from "@/apps/time-tracker/store/clock-it";
import { storeToRefs } from "pinia";
import ArrowLeftIcon from "@/assets/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/assets/ArrowRightIcon.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";

const emits = defineEmits(["appSelected"]);
const store = useStore();
const user_id = ref(store.user.user_id);
let encryptedId = encodeUrlPath(user_id.value?.toString());

const userPermissions = clockItUserPermissions();
const onClockIt = ref(false);
const onDayPLanner = ref(false);
const onTimeSheet = ref(false);
const onPreference = ref(false);
const onPeople = ref(false);
const onProjects = ref(false);
const onTeamSheet = ref(false);
const onReport = ref(false);
const onSettings = ref(false);

const toggleonClockIt = (status: boolean) => {
  onClockIt.value = status;
};
const toggleOnDayPlanner = (status: boolean) => {
  onDayPLanner.value = status;
};
const toggleOnTimeSheet = (status: boolean) => {
  onTimeSheet.value = status;
};
const toggleOnPreference = (status: boolean) => {
  onPreference.value = status;
};
const toggleOnPeople = (status: boolean) => {
  onPeople.value = status;
};
const toggleOnProjects = (status: boolean) => {
  onProjects.value = status;
};
const toggleOnTeamSheet = (status: boolean) => {
  onTeamSheet.value = status;
};
const toggleOnReport = (status: boolean) => {
  onReport.value = status;
};
const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};

const loginHint: string = store.usersHint;
const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}`;
  }
};

const savedClocks = useClockStore();
const {
  hours,
  minutes,
  seconds,
  startTimer,
  audio,
  timerAtZero,
  runningTimeContext,
  countdownStarted
} = storeToRefs(savedClocks);
const timeDisplay = computed(
  () => hours.value + ":" + minutes.value + ":" + seconds.value
);

onMounted(() => {
  if (window.innerWidth <= 1366) {
    store.showAll = false;
  }
  const resumeAppsCompCountDown = () => {
    countdownStarted.value = true;
    runningTimeContext.value = setInterval(() => {
      if (Number(seconds.value) > 0) {
        seconds.value = (seconds.value as number) - 1;
      } else if (minutes.value > 0) {
        minutes.value--;
        seconds.value = 59;
      } else if (hours.value > 0) {
        hours.value--;
        minutes.value = 59;
        seconds.value = 59;
      } else {
        clearInterval(runningTimeContext.value);
        countdownStarted.value = false;
        handleTimerEnd();
      }
    }, 1000) as unknown as number;
  };

  if (
    countdownStarted.value &&
    (hours.value > 0 || minutes.value > 0 || Number(seconds.value) > 0)
  ) {
    resumeAppsCompCountDown();
  }

  const handleTimerEnd = () => {
    if (audio.value) {
      audio.value.play();
    }
    timerAtZero.value = true;
  };
});
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #474d66;
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.settings::-webkit-scrollbar {
  display: none;
}
ul {
  margin: 19px 0;
}
li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.H700 {
  z-index: 2;
}
@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}
@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }
  .menu_view {
    width: auto;
  }
}
</style>
